import React from 'react'

const Safety = () => {
  return (
    <div className="bg-white px-2 md:px-0 dark:bg-light-dark pt-24 pb-12">
      <div className="md:container mx-auto flex flex-col items-center">
        <span className="mt-4 mb-4 text-2xl text-black dark:text-white font-semibold">
          Important Disclaimer
        </span>
        <p className="text-left text-xl text-grey dark:text-dark-white max-w-2xl font-semibold">
          <center>
            The Pulse AirDAO sacrifice is creating a set of people who believe
            in a green, sustainable future for blockchains. Excessive, wasteful
            energy consumption is a huge problem in cryptocurrency mining.
          </center>
        </p>
        <br></br>
        <p className="text-left text-xl text-grey dark:text-dark-white max-w-2xl font-semibold">
          <center>
            One of the intended goals of proof of stake blockchains like
            PulseChain is to utilize greener technology to help protect the
            environment. We believe that blockchains should NOT destroy the
            environment. If you agree with this, then you can show your support
            by sacrificing to the address above.
          </center>
        </p>
        <br></br>
        <p className="text-left text-xl text-grey dark:text-dark-white max-w-2xl font-semibold">
          <center>
            If support this and participated in the sacrifice, you will be
            airdropped AIR tokens on Pulse Chain.
          </center>
        </p>
      </div>
    </div>
  )
}

export default Safety
